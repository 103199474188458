<template>
  <section>
    <div class="radio-inline-spacing">
      <label class="font_size_label_empresa font-weight-light">Empresa com valor diferenciado?</label>
      <b-form-radio
          :state="errorApi.error ? false:null"
          v-model="empresaPossuiValorDiferenciado.possui"
          name="radio-valor-diferenciado"
          :value="true"
          :disabled="!podeCadastrar"
          v-b-tooltip.hover="!podeCadastrar ? 'Esta empresa é classificada como Base Nacional. O valor diferenciado não está disponível para empresas da Base Nacional.' : ''"
      >
        Sim
      </b-form-radio>
      <b-form-radio
          :state="errorApi.error ? false:null"
          v-model="empresaPossuiValorDiferenciado.possui"
          name="radio-valor-diferenciado"
          :value="false"
          v-b-tooltip.hover="empresaPossuiValorDiferenciado.possui_em_outra_dr ? 'A empresa possui valor diferenciado em outro departamento. Ao invés de editar esse campo, você pode remover todos os valores diferenciados do seu departamento.' : ''"
          :disabled="!podeCadastrar || empresaPossuiValorDiferenciado.possui_em_outra_dr"
      >
        Não
      </b-form-radio>
    </div>
    <small v-if="errorApi.error" class="text-danger">
      {{ errorApi.error }}
    </small>
    <template v-if="empresaPossuiValorDiferenciado.possui">
      <b-button @click="novoValorDiferenciado"
                type="reset"
                class="cor_botao mt-2"
                :disabled="adicionarCampanhaDrDisable || !podeCadastrar"
                variant="outline-primary"
      >
        <feather-icon
            icon="PlusIcon"
            class="mr-50"
        />
        <span class="align-middle" >Adicionar DR/Campanha</span>
      </b-button>
      <template v-if="podeCadastrar">
        <div v-for="(valorDiferenciadoItem, indexValorDiferenciado) in valorDiferenciadoInputs"
             v-if="valorDiferenciadoItem"
             :key="valorDiferenciadoItem.id">
        <b-col
            sm="12"
            md="6"
            class="pr-0 pl-0"
            v-if="indexValorDiferenciado === 0 && valorDiferenciadoItem === valorDiferenciadoCadastro"
        >
          <ValorDiferenciadoItem :index-valor-diferenciado="indexValorDiferenciado"
                                 :valor-diferenciado-item="valorDiferenciadoItem"
                                 @resolverVacinasValores="resolverVacinasValoresDaCampanha"
                                 @resolverCampanhasDoValorDiferenciadoItem="resolverCampanhasDoValorDiferenciadoItem"
                                 @editarValorDeValorCategoria="editarValorDeValorCategoria"
                                 @removerValorDiferenciado="removerValorDiferenciado"
          />
        </b-col>

        <b-col
            sm="12"
            md="6"
            class="pr-0 pl-0 mt-2"
            v-else
        >
        <app-collapse accordion class="accordion-valor-diferenciado">
            <app-collapse-item :title="valorDiferenciadoItem.departamentoSelecionado.descricao + ' - ' +  valorDiferenciadoItem.campanhaSelecionada.descricao"
                               :key="indexValorDiferenciado"
            >
              <ValorDiferenciadoItem :index-valor-diferenciado="indexValorDiferenciado"
                                     :valor-diferenciado-item="valorDiferenciadoItem"
                                     :possui-campanha-departamento="false"
                                     @resolverVacinasValores="resolverVacinasValoresDaCampanha"
                                     @resolverCampanhasDoValorDiferenciadoItem="resolverCampanhasDoValorDiferenciadoItem"
                                     @editarValorDeValorCategoria="editarValorDeValorCategoria"
                                     @removerValorDiferenciado="removerValorDiferenciado"
              />
            </app-collapse-item>
        </app-collapse>
        </b-col>
        </div>
      </template>

    </template>
  </section>
</template>

<script>

import {
  BButton,
  BCol,
  BFormGroup, BFormInput,
  BFormRadio, BImg, BInputGroup, BInputGroupPrepend, BRow,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ricon from '@/assets/images/icons/RIcon.svg';
import ValorDiferenciadoInput
  from "@/views/custom-pages/empresa/tabs-empresa/partes/valor-diferenciado/Valor-diferenciado-input.vue";
import {getUserData} from "@/auth/utils";
import {ValidationProvider} from "vee-validate";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ValorDiferenciadoItem
  from "@/views/custom-pages/empresa/tabs-empresa/partes/valor-diferenciado/Valor-diferenciado-item.vue";

import { setModalValorDiferenciadoRemover } from "@core/utils/store/setStore";
import { getModalValorDiferenciadoRemover } from "@core/utils/store/getStore";
import helpers from '@/helpers'

export default {
  computed: {
    modalValorDiferenciadoRemover() {
      return getModalValorDiferenciadoRemover();
    },
  },
  components: {
    ValorDiferenciadoItem,
    AppCollapseItem, AppCollapse,
    ValorDiferenciadoInput,
    BButton,
    BRow,
    BInputGroup, BImg, BFormInput, BInputGroupPrepend,
    BCol,
    BFormRadio,
    BFormGroup,
    vSelect,
    ValidationProvider
  },
  props: {
    errorApi: {
      required: true,
    },
    empresaPossuiValorDiferenciado: {
      required: true
    },
    value: {
      required: false
    },
    podeCadastrar: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      moneyMask: {
        decimal: ',',
        thousands: '.',
        precision: 2,
      },
      valorDiferenciadoInputs: [],
      valorDiferenciadoCadastro: null,
      valorDiferenciadoExclusao: null,
      adicionarCampanhaDrDisable: false,
      valorDiferenciadoInputsApresentacao: [],
      departamentos: [],
      campanhasPorDepartamento: [],
      Ricon,
      valorDiferenciadoInicial: []
    };
  },
  created() {
    this.departamentos = this.resolverDepartamentos();
  },
  async mounted() {
    this.valorDiferenciadoInputs = await this.resolverValoresDiferenciadosExistentes();
    this.valorDiferenciadoInicial = [...this.value];

    this.valorDiferenciadoInputs.forEach(campanha => {
      campanha.vacinasValores.forEach(vacinaValor => {
        vacinaValor.valores.sort((a, b) => a.id_categoria - b.id_categoria);
      });
    });
  },
  watch: {
    'modalValorDiferenciadoRemover.confirmado'() {
      if (this.modalValorDiferenciadoRemover.confirmado) {
        this.removerValorDiferenciadoConfirmado();
        setModalValorDiferenciadoRemover({show: false, confirmado: false})
      }
    }
  },
  methods: {
    novoValorDiferenciado() {
      let valorDiferenciado = this.resolverValorDiferenciadoInput({
        departamentoOptions: this.departamentos
      });

      this.valorDiferenciadoCadastro = valorDiferenciado;
      this.adicionarCampanhaDrDisable = true;

      this.$emit('novoValorDiferenciado', valorDiferenciado);
      this.valorDiferenciadoInputs.unshift(valorDiferenciado);
    },

    resolverValorDiferenciadoInput(
        {
          departamentoSelecionado = null,
          campanhaSelecionada = null,
          idCampanhaVacina = null,
          vacinasValores = null,
          departamentoOptions = [],
          campanhaOptions = []
        } = {}
    ) {

      let departamentos = departamentoOptions.map(departamento => {
           return {
             idDepartamento: departamento.idDepartamento,
             descricao: departamento.descricao
           }
      })

      return {
          id:  helpers.geraNumeroAleatorio(),
          departamentoSelecionado: departamentoSelecionado,
          campanhaSelecionada: campanhaSelecionada,
          idCampanhaVacina: idCampanhaVacina,
          vacinasValores: vacinasValores,
          departamentoOptions: departamentos,
          campanhaOptions: [...campanhaOptions]
        }
    },

    resolverVacinasValoresPadrao(comLabel = true) {
      return [
        this.resolverValorDiferenciadoCategoria(
            1,
            '0,00',
            comLabel ? 'Trabalhador': null,
            'trabalhador'
        ),
        this.resolverValorDiferenciadoCategoria(
            2,
            '0,00',
            comLabel ? 'Dependente' : null,
            'dependente'
        ),
        this.resolverValorDiferenciadoCategoria(3,
            '0,00',
            comLabel ? 'Terceiro': null,
            'terceiro'
        ),
      ]
    },

    resolverValorDiferenciadoCategoria(idCategoria, value, label, id, idCampanhaVacinaEmpresa = null) {
      const valorDiferenciadoCategoria = {
        id_categoria: idCategoria,
        value: value,
        label: label,
        id: id
      }

      if (idCampanhaVacinaEmpresa) {
        valorDiferenciadoCategoria['id_campanha_vacina_empresa'] = idCampanhaVacinaEmpresa;
      }

      return valorDiferenciadoCategoria;
    },

    resolverDepartamentos() {
      const userData = getUserData();
      const departamento = userData.departamento;

      let idDepartamento = departamento['id_departamento'];
      let descricao = departamento['descricao'];
      let uf = departamento['uf'];

      return [{idDepartamento, descricao, uf}];
    },

    async resolverCampanhasDoValorDiferenciadoItem(valorDiferenciadoItem) {
      let departamentoSelecionado = valorDiferenciadoItem.departamentoSelecionado;

      let outputRequestCampanha = await this.$http.get(
          this.$api.campanha(),
          {
              params: {
                uf: departamentoSelecionado.uf,
                porPagina: 99,
                situacao: ['Em andamento', 'Agendada']
              }
          }
      );

      const campanhas = outputRequestCampanha.data;
      const campanhasRegionais = campanhas.filter(campanha => campanha.tipo === 'Regional');

      const valorDiferenciadoInputs = Array.isArray(this.valorDiferenciadoInputs) ? this.valorDiferenciadoInputs : [];

      const idsCampanhasJaUtilizadas = valorDiferenciadoInputs.map(valorDiferenciado => {
        if (valorDiferenciado) {
          return valorDiferenciado.campanhaSelecionada?.idCampanha;
        }
      });

      const campanhasDisponiveis = campanhasRegionais.filter(campanha => {
        return !idsCampanhasJaUtilizadas.includes(campanha.id_campanha)
      })

      await this.armazenarCampanhas(campanhasDisponiveis, departamentoSelecionado.idDepartamento);

      valorDiferenciadoItem.campanhaOptions = this.resolverCampanhaOptions(campanhasDisponiveis)
    },

    resolverCampanhaOptions(campanhas) {
      return campanhas.map(campanha => {
        return {
          idCampanha: campanha.id_campanha,
          descricao: campanha.campanha_descricao,
        }
      });
    },

    resolverVacinasValoresDaCampanha(valorDiferenciadoItem) {
      const vacinasValoresExistentes = this.valorDiferenciadoInicial.flatMap(
          (valorDiferenciado) => valorDiferenciado.vacinasValores
      );

      this.resolverVacinasValores(
          valorDiferenciadoItem,
          vacinasValoresExistentes.length === 0 ? null : vacinasValoresExistentes
      );
    },

    resolverVacinasValores(valorDiferenciadoItem, valoresExistentes = null) {
      this.adicionarCampanhaDrDisable = false;

      const campanhaDepartamento = this.campanhasPorDepartamento.find(function (campanhasDepartamento) {
        return campanhasDepartamento.campanhas.id_campanha === valorDiferenciadoItem.campanhaSelecionada.idCampanha
      });

      const campanhasVacina = campanhaDepartamento.campanhas.campanha_vacina.map(campanhaVacina => {
          return {
            idCampanhaVacina: campanhaVacina.id_campanha_vacina,
            idVacina: campanhaVacina.id_vacina
          }
        }
      )

      const vacinas = campanhaDepartamento.campanhas.vacina.map(vacina => {
        const campanhaVacina = campanhasVacina.find(campanhaVacina => campanhaVacina.idVacina === vacina.id_vacina);
        return {
          nome: vacina.nome_tecnico,
          idCampanhaVacina: campanhaVacina.idCampanhaVacina
        }
      });

      const vacinasValores = [];

      vacinas.forEach((vacina, index) => {

        let vacinaValor = null;

        if (valoresExistentes) {
          vacinaValor = valoresExistentes.find(
              valorExistente => valorExistente.id_campanha_vacina === vacina.idCampanhaVacina
          );
        }

        if (!vacinaValor) {
          vacinasValores.push({
            vacina: vacina.nome,
            idCampanhaVacina: vacina.idCampanhaVacina,
            valores: this.resolverVacinasValoresPadrao(index === 0),
          })
        } else {
          const valores = vacinaValor.valores.map(valorCategoria => {
            const label = this.resolveLabelCategoriaPorId(valorCategoria.id_categoria);
            return this.resolverValorDiferenciadoCategoria(
                valorCategoria.id_categoria,
                this.converterFloatEmString(valorCategoria.valor),
                index === 0 ? label : null,
                label.toLowerCase(),
                valorCategoria.id_campanha_vacina_empresa
            )
          });

          vacinasValores.push({
            vacina: vacina.nome,
            idCampanhaVacina: vacina.idCampanhaVacina,
            valores: valores,
          })
        }
      });

      valorDiferenciadoItem.vacinasValores = vacinasValores;
    },

    converterFloatEmString(value) {
      let numericValue = parseFloat(value);

      const moneyMask = {
        decimal: ',',
        thousands: '.',
        precision: 2,
      };

      let formattedValue = numericValue.toFixed(moneyMask.precision);

      formattedValue = formattedValue.replace('.', moneyMask.decimal);

      formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, moneyMask.thousands);

      return formattedValue;
    },

    async armazenarCampanhas(campanhas, idDepartamento) {
      const departamentoExiste = this.campanhasPorDepartamento.find(
          campanhasDepartamento => campanhasDepartamento.idDepartamento === idDepartamento
      )

      if (!departamentoExiste) {
        for (const campanha of campanhas) {

          let outputRequestCampanha = await this.$http.get(
              this.$api.getShowAndEditCampanha(campanha.id_campanha)
          );

          this.campanhasPorDepartamento.push({
            idDepartamento: idDepartamento,
            campanhas: outputRequestCampanha.data
          })
        }
      }
    },

    removerValorDiferenciado(indexValorDiferenciado, valorDiferenciadoItem) {
      this.valorDiferenciadoExclusao = {
        index: indexValorDiferenciado,
        valorDiferenciadoItem: valorDiferenciadoItem
      }

      if (valorDiferenciadoItem?.jaExistente) {
        setModalValorDiferenciadoRemover({show: true});
      } else {
        this.removerValorDiferenciadoConfirmado()
      }
    },

    removerValorDiferenciadoConfirmado() {
      const primeiroValorDiferenciado = this.valorDiferenciadoInputs[0] ?? null;

      if (primeiroValorDiferenciado && !primeiroValorDiferenciado?.campanhaSelecionada) {
        this.adicionarCampanhaDrDisable = false;
      }

      const indexValorDiferenciado = this.valorDiferenciadoExclusao.index;
      const valorDiferenciadoItem = this.valorDiferenciadoExclusao.valorDiferenciadoItem;

      this.valorDiferenciadoInputs = this.valorDiferenciadoInputs.map((valorDiferenciadoInput) => {
        if (valorDiferenciadoItem === valorDiferenciadoInput) {
          return null;
        } else {
          return valorDiferenciadoInput;
        }
      })

      this.valorDiferenciadoExclusao = null;

      this.$emit('removerValorDiferenciado', indexValorDiferenciado);
    },

    async resolverValoresDiferenciadosExistentes() {
      const valorDiferenciado = [];

      for (const valorDiferenciadoItemDados of this.value || []) {
        const departamentoSelecionado = this.departamentos.find(
            departamento => valorDiferenciadoItemDados.id_departamento === departamento.idDepartamento
        )

        const valorDiferenciadoItem = this.resolverValorDiferenciadoInput(
            {
              departamentoSelecionado: departamentoSelecionado,
              departamentoOptions: this.departamentos
            }
        );

        await this.resolverCampanhasDoValorDiferenciadoItem(valorDiferenciadoItem);

        const campanhaSelecionada = valorDiferenciadoItem.campanhaOptions.find(
            campanha => campanha.idCampanha === valorDiferenciadoItemDados.id_campanha
        );

        valorDiferenciadoItem.campanhaSelecionada = campanhaSelecionada;
        valorDiferenciadoItem.jaExistente = true;

        this.resolverVacinasValores(valorDiferenciadoItem, valorDiferenciadoItemDados.vacinasValores);

        valorDiferenciado.push(
            valorDiferenciadoItem
        );
      }

      return valorDiferenciado;
    },

    resolveLabelCategoriaPorId(idCategoria) {
      const categorias = {
        1: 'Trabalhador',
        2: 'Dependente',
        3: 'Terceiro'
      };

      return categorias[idCategoria] || '-';
    },

    editarValorDeValorCategoria(valorCategoria, valor, indexValorDiferenciado) {
      valorCategoria.value = valor;
      const valorDiferenciadoInput = this.valorDiferenciadoInputs[indexValorDiferenciado];
      this.$emit('atualizarValorDiferenciadoItem', {indexValorDiferenciado, valorDiferenciadoInput});
    }
  },
};

</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
.remover-valor-diferenciado {
  display: flex;
  align-items: center;
}

.remover-valor-diferenciado span {
  margin-left: 5px;
}

.disabled-collapse {
  pointer-events: none;
  background-color: #ededed
}

.separator {
  border-left: 1px solid #EBE9F1;
  height: 100%;
}

.background-custom-blue {
  background-color: rgba(39, 114, 192, 0.12);
  border-radius: 6px;
}

.custom-append-input {
  border-radius: 0.357rem;
  z-index: 5;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.disabled-append {
  background: $placeholder-color !important;
}

.font-bold {
  font-weight: 800;
  font-size: 2rem;
}

.border-color-input {
  border-color: $custom-blue;
  margin-left: -4px;
}

.border-color-input-disabled {
  border-color: $placeholder-color !important;
}

.check-margin {
  margin-top: 5px !important;
}

.max-width-form {
  max-width: 85%;
}


.radio-inline-spacing {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > * {
    margin-right: 1.5rem;
    margin-bottom: 0.2rem;
  }
}

.col-vacina {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-valor-diferenciado {
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
}

.row-valor-diferenciado {
  background-color: #F8F8F8;
  padding: 22px;
  border-radius: 10px;
  min-height: 15px;
}

.cor_botao {
  color: #2772C0 !important;
  border: 1px solid #2772C0 !important;
}

.campanha-departamento {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.63px;
  text-align: left;
  display: flex;
}

.vacina-nome {
  bottom: 5px;
  position: absolute;
  padding-left: 15px;
  padding-right: 15px;
}

label.selecione-campanha {
  text-align: center;
  width: 100%;
  opacity: 0.7;
}

button.btn.mt-bt-remove.btn-danger {
  display: inline-flex;
}

.remover {
  display: flex;
  justify-content: flex-end;
  padding-right: 0px;
}

.accordion-valor-diferenciado .card-header {
  background: #f2f7fb;
  color: #2772c0;
  padding-left: 30px !important;
}

.accordion-valor-diferenciado .card-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 100%;
  background-color: #2772c0;
}


.accordion-valor-diferenciado .card-body {
  padding: 0 !important;
}

</style>