<template>
    <div v-show="show" class="alertaCustomizado rounded">   
      <span class="espacoIcone">
        <feather-icon class="iconeAlerta" icon="AlertCircleIcon" size="30"/>
      </span>
      <span class="alertaCorTextoCustomizada"> 
        O cadastro desta empresa não possui todos os dados obrigatórios preenchidos. Edite a empresa e preencha os dados faltantes para que a empresa esteja apta para aderir a uma campanha.
      </span>
    </div>
</template>

<script>
import {actions, subjects} from "@/libs/acl/rules";

export default {
    props: {
        show: {
            required: true,
            type: Boolean,
        }
    },
    data() {
      return {
        companyUser: this.$can(actions.VISUALIZAR, subjects.EMPRESAS_AE),
      };
    },
}
</script>

<style>
  .alertaCustomizado{
    background-color: #FFF3E7;
    padding: 10px;
    display:flex;
    align-items:center;
    margin-bottom: 15px;
  }
  .alertaCorTextoCustomizada{
    font-size: 14px;
    font-style: "Montserrat";
    font-weight: bold;
    color:#FF7C02;
  }
  .iconeAlerta{
    color:#FF7C02;
  }
  .espacoIcone{
    margin-right: 10px;
    align-items:center;
    display:flex;
  }
</style>