<template>
  <div>
    <b-row>
      <!-- Empresa com convênio? -->
      <b-col xl="4" cols="4">
        <div class="radio-inline-spacing-valor">
          <b-form-group
            label="Empresa com convênio?"
            label-for="possui_convenio-empresa"
            label-class="font_size_label_empresa"
          >
            <b-form-radio
              v-model="empresa.possui_convenio"
              name="possui_convenio-empresa"
              :value="true"
            >
              Sim
            </b-form-radio>
            <b-form-radio
              v-model="empresa.possui_convenio"
              name="possui_convenio-empresa"
              :value="false"
            >
              Não
            </b-form-radio>
          </b-form-group>
        </div>
        <small v-if="errorApi.possui_convenio" class="text-danger">
          {{ errorApi.possui_convenio }}
        </small>
        <small v-if="errorApi.error" class="text-danger">
          {{ errorApi.error }}
        </small>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BFormRadio,
    BFormGroup,
    vSelect,
  },
  props: {
    errorApi: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      casoEdicao: false,
      erros: "",
      removeCategoryItem: "Convênio",
      empresa: this.value,
    };
  }
};
</script>

<style lang="scss" scoped>
.radio-inline-spacing-valor .form-group .custom-radio {
  display: inline-block;
}
.radio-inline-spacing-valor .form-group .custom-radio:first-child {
  margin-right: 1.5rem;
}
</style>