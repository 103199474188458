<template>
    <b-row>
        <b-col xl="4" cols="12">
            <b-form-group 
                label="Nome do responsável"
                label-for="nome-responsavel-input"
                label-class="font_size_label_empresa"
            >
                <b-form-input 
                    :state="errorApi.nome || errorApi.error ? false:null"
                    id="nome-responsavel-input" 
                    v-model="value.nome"
                    placeholder="Responsável pela empresa"
                    v-mask="('X'.repeat(200))"
                />
                <small v-if="errorApi.nome" class="text-danger">
                    {{ errorApi.nome }}
                </small>
                <small v-if="errorApi.error" class="text-danger">
                    {{ errorApi.error }}
                </small>
            </b-form-group>
        </b-col>
        <b-col xl="4" cols="12">
            <b-form-group 
                label="Cargo"
                label-for="cargo-input"
                label-class="font_size_label_empresa"
            >
                <b-form-input 
                    :state="errorApi.cargo || errorApi.error ? false:null"
                    id="cargo-input" 
                    v-model="value.cargo"
                    placeholder="Seu ofício na empresa"
                    v-mask="('X'.repeat(200))"
                />
                <small v-if="errorApi.cargo" class="text-danger">
                    {{ errorApi.cargo }}
                </small>
                <small v-if="errorApi.error" class="text-danger">
                    {{ errorApi.error }}
                </small>
            </b-form-group>
        </b-col>
        <b-col xl="4" cols="12">
            <b-form-group 
                label="E-mail"
                label-for="email-input"
                label-class="font_size_label_empresa"
            >
                <b-form-input 
                    :state="errorApi.email || errorApi.error ? false:null"
                    id="email-input" 
                    v-model="value.email"
                    placeholder="E-mail para contato"
                    v-mask="('X'.repeat(255))"
                />
                <small v-if="errorApi.email" class="text-danger">
                    {{ errorApi.email }}
                </small>
                <small v-if="errorApi.error" class="text-danger">
                    {{ errorApi.error }}
                </small>
            </b-form-group>
        </b-col>
        <b-col xl="4" cols="12">
            <b-form-group 
              label="Telefone para contato principal"
              label-for="telefone-input"
              label-class="font_size_label_empresa"
            >
                <validation-provider
                    #default="{ errors }"
                    name="Telefone"
                    :rules="'min:10|max:11'"
                >
                    <vue-cleave
                        id="telefone-input"
                        v-model="value.telefone"
                        :raw="true"
                        :options="mascaraTelefone"
                        class="form-control"
                        placeholder="(99) 99999-9999"
                        name="Telefone"
                    />
                    <small v-if="errors[0]" class="text-danger">
                        Este campo deve ter no mínimo 10 números
                    </small>
                    <small v-if="errorApi.telefone" class="text-danger">
                        {{ errorApi.telefone }}
                    </small>
                    <small v-if="errorApi.error" class="text-danger">
                        {{ errorApi.error }}
                    </small>
                </validation-provider>
            </b-form-group>
        </b-col>
        <b-col xl="4" cols="12">
            <b-form-group 
                label="Telefone para contato secundário"
                label-for="telefone-secundario-input"
                label-class="font_size_label_empresa"
            >
                <validation-provider
                    #default="{ errors }"
                    name="TelefoneSecundario"
                    :rules="'min:10|max:11'"
                >
                    <vue-cleave
                        id="telefone-secundario-input"
                        v-model="value.telefone_secundario"
                        :raw="true"
                        :options="mascaraTelefone"
                        class="form-control"
                        placeholder="(99) 99999-9999"
                        name="TelefoneSecundario"
                    />
                    <small v-if="errors[0]" class="text-danger">
                        Este campo deve ter no mínimo 10 números
                    </small>
                    <small v-if="errorApi.telefone_secundario" class="text-danger">
                        {{ errorApi.telefone_secundario }}
                    </small>
                    <small v-if="errorApi.error" class="text-danger">
                        {{ errorApi.error }}
                    </small>
                </validation-provider>
            </b-form-group>
        </b-col>
        <div class="containerEnderecoContato" :key="updateAddress" v-if="showAddress">
            <p class="titleEnderecoContato">Endereço do contato:</p>
            <p class="textEnderecoContato">
                {{ 
                    (value.logradouro ? value.logradouro : '--') + ' n ' + 
                    (value.numero ? value.numero : '--') + ' ' + 
                    (value.bairro ? value.bairro : '--') + ', ' + 
                    (value.cidade ? value.cidade : '--') + ' - ' +
                    (value.estado ? value.estado : '--') + ' CEP: '
                }} 

                {{ (value.cep ? value.cep : '') | VMask('#####-###') }}.
            </p>
        </div>
    </b-row>
</template>

<script>
import { BCard, BFormInput, BCol, BRow, BFormGroup } from 'bootstrap-vue'
import VueCleave from 'vue-cleave-component'
import { ValidationProvider } from "vee-validate"

export default {
	components: {
        BCard, 
        BFormInput, 
        BCol, 
        BRow, 
        BFormGroup,
        VueCleave,
        ValidationProvider
	},

    props: {
		errorApi: {
			type: Array|Object,
			required: true
		},
		value: {
			type: Object,
			required: true
		},
        showAddress: {
            type: Boolean,
            required: true
        }
	},

    mounted() {
        setInterval(() => {
            this.updateAddress++;
        }, 1000);
    },

    data() {
		return {
			mascaraTelefone: {
				delimiters: ['(', ') ', '-'],
				blocks: [0, 2, 5, 4],
				numericOnly: true
			},
			erros: null,
            interval: null,
            updateAddress: 0,
            showEndereco: false,
            formatedAddress: ""
		}
	}
}
</script>

<style scoped>
  .textEnderecoContato {
      font-size: 1rem;
      padding: 0;
      margin: 0
  }

  .titleEnderecoContato{
      font-size: 1.2rem;
  }

  .containerEnderecoContato {
      width: 100%;
      background: #0020600A;
      padding: 12px 22px 12px 22px;
      border-radius: 4px;
      margin-left: 1rem;
      margin-right: 1rem;
      margin-top: 10px;
  }
</style>