<template>
	<b-modal
		id="addContatoEndereco"
		no-close-on-esc
		no-close-on-backdrop
		hide-header
		hide-footer
		centered
		v-model="showModal"
	>
		<div class="d-flex flex-column my-2">
			<h2 class="mb-2">{{ value.contatoCadastrado ? 'Editar contato' : 'Cadastrar contato'  }}</h2>

			<small class="text-danger" v-if="errorContato">{{ errorContato }}</small>

			<b-form>
				<b-row>
					<b-col lg="12" md="12" sm="12">
						<b-form-group label="Nome do responsável*" label-for="responssavel-input">
							<b-form-input
								id="responssavel-input"
								v-model="value.nome"
								placeholder="Responsável pela empresa"
							/>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row class="mt-1">
					<b-col lg="6" md="12" sm="12">
						<b-form-group label="Cargo*" label-for="cargoContato">
							<b-form-input
								id="cargoContato"
								v-model="value.cargo"
								placeholder="Seu ofício na empresa"
							/>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="12" sm="12">
						<b-form-group label="E-mail*" label-for="emailContato">
							<b-form-input
								id="emailContato"
								v-model="value.email"
								placeholder="E-mail para contato"
							/>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row class="mt-1">
					<b-col lg="6" md="12" sm="12">
						<b-form-group label="Telefone para contato principal*" label-for="telefonePrincipalContato">
							 <vue-cleave
								id="telefonePrincipalContato"
								v-model="value.telefone"
								:raw="true"
								:options="mascaraTelefone"
								class="form-control"
								placeholder="(00) 00000-0000"
								name="Telefone"
							/>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="12" sm="12">
						<b-form-group label="Telefone para contato secundário" label-for="telefoneSecundarioContato">
							<vue-cleave
								id="telefoneSecundarioContato"
								v-model="value.telefone_secundario"
								:raw="true"
								:options="mascaraTelefone"
								class="form-control"
								placeholder="(00) 00000-0000"
								name="Telefone"
							/>
						</b-form-group>
					</b-col>
				</b-row>
			</b-form>
		</div>
		<section class="d-flex justify-content-center mb-1 mt-3">
			<b-button
				id="saveContato"
				class="mr-2 mb-2-sm"
				variant="custom-blue"
				@click.prevent="saveContato">Salvar contato</b-button>
			<b-button
				id="cancelSaveContato"
				variant="outline-danger"
				@click.prevent="cancel"
			>{{ value.contatoCadastrado ? 'Remover contato' : 'Cancelar' }}</b-button>
		</section>
	</b-modal>
</template>

<script>
import {
	BModal,
	BForm,
	BCol,
	BRow,
	BFormGroup,
	BFormInput,
	BButton,
	BCard,
	BInputGroup,
} from "bootstrap-vue";
import VueCleave from 'vue-cleave-component'

export default {
	components: {
		BModal,
		BForm,
		BCol,
		BRow,
		BFormGroup,
		BFormInput,
		BButton,
		BCard,
		BInputGroup,
		VueCleave
	},

	props: {
		showModal: {
			required: true,
			type: Boolean,
		},
		value: {
			required: true,
			type: Object|null
		}
	},

	data() {
		return {
			errorContato: null,
			mascaraTelefone: {
				delimiters: ['(', ') ', '-'],
				blocks: [0, 2, 5, 4],
				numericOnly: true
			}
		}
	},

	methods: {
		saveContato() {
			this.errorContato = null;
			if(this.temContatoCompleto(this.$props.value)) {
				this.$emit('closeModal');
			} else {
				this.errorContato = 'Para cadastrar o contato é necessário preencher todos os campos, exceto o contato secundário';
			}
		},

		cancel() {
			this.$props.value.contatoCadastrado = false;
			this.$props.value.nome = null,
			this.$props.value.cargo = null,
			this.$props.value.email = null,
			this.$props.value.telefone = null,
			this.$props.value.telefone_secundario = null,

			this.$emit('cancel');
		},

		temContatoCompleto(endereco) { 
			if(endereco.nome && endereco.cargo && endereco.email && endereco.telefone) {
				this.$props.value.contatoCadastrado = true; 
				return true;
			}

			this.$props.value.contatoCadastrado = false; 
			return false;
		}
	},

	watch: {
		showModal() {
			this.temContatoCompleto(this.$props.value);
			this.errorContato = null;
		}
	}
};
</script>

<style scoped>
</style>
