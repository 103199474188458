var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('b-row',[_c('b-col',{attrs:{"xl":"6","cols":"12"}},[_c('b-form-group',{attrs:{"label":"CNPJ ou CPF*","label-for":"cnpj-cpf-input","label-class":"font_size_label_empresa"}},[_c('validation-provider',{attrs:{"name":"Documento","rules":_vm.documentoCnpj ? 'required|length:18' : 'required|length:14'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(
              _vm.valueLocal.documento.length <= 14 && !_vm.documentoCnpj
                ? '###.###.###-##'
                : '##.###.###/####-##'
            ),expression:"\n              valueLocal.documento.length <= 14 && !documentoCnpj\n                ? '###.###.###-##'\n                : '##.###.###/####-##'\n            "}],staticClass:"form-control",attrs:{"state":errors.length > 0 ||
              _vm.errorApi.documento ||
              (_vm.documentoError != 'inicio' && _vm.documentoError != '') ||
              _vm.errorApi.error
                ? false
                : null,"id":"cnpj-cpf-input","placeholder":"00.000.000/0000-00","disabled":_vm.edicao ? _vm.edicao : _vm.documentoDisabled},on:{"input":_vm.validaDocumento},model:{value:(_vm.valueLocal.documento),callback:function ($$v) {_vm.$set(_vm.valueLocal, "documento", $$v)},expression:"valueLocal.documento"}}),(errors[0] && _vm.documentoError == 'inicio')?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.documentoError != 'inicio')?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.documentoError)+" ")]):_vm._e(),(_vm.errorApi.documento)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.documento)+" ")]):_vm._e(),(_vm.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.error)+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-overlay',{staticClass:"w-100 mt-1",attrs:{"show":!_vm.loadingShow,"rounded":"lg","opacity":"0.6"}},[(_vm.documentoCnpj)?_c('section',[_c('b-row',[_c('b-col',{attrs:{"xl":"6"}},[_c('b-form-group',{attrs:{"label":"Nome Fantasia*","label-for":"nome-fantasia-input","label-class":"font_size_label_empresa"}},[_c('validation-provider',{attrs:{"name":"NomeFantasia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('X'.repeat(255)),expression:"'X'.repeat(255)"}],attrs:{"state":errors.length > 0 ||
                  _vm.errorApi.nome_fantasia ||
                  _vm.errorApi.error
                    ? false
                    : null,"id":"nome-fantasia-input","placeholder":"Nome fantasia da empresa","disabled":_vm.disabledAll},model:{value:(_vm.valueLocal.nome_fantasia),callback:function ($$v) {_vm.$set(_vm.valueLocal, "nome_fantasia", $$v)},expression:"valueLocal.nome_fantasia"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.errorApi.nome_fantasia)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.nome_fantasia)+" ")]):_vm._e(),(_vm.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.error)+" ")]):_vm._e()]}}],null,false,1918718315)})],1)],1),_c('b-col',{attrs:{"xl":"6"}},[_c('b-form-group',{attrs:{"label":"Razão Social*","label-for":"razao-social-input","label-class":"font_size_label_empresa"}},[_c('validation-provider',{attrs:{"name":"RazaoSocial","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('X'.repeat(255)),expression:"'X'.repeat(255)"}],attrs:{"state":errors.length > 0 || _vm.errorApi.razao_social || _vm.errorApi.error
                    ? false
                    : null,"id":"razao-social-input","placeholder":"Razão social da empresa","disabled":_vm.disabledAll},model:{value:(_vm.valueLocal.razao_social),callback:function ($$v) {_vm.$set(_vm.valueLocal, "razao_social", $$v)},expression:"valueLocal.razao_social"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.errorApi.razao_social)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.razao_social)+" ")]):_vm._e(),(_vm.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.error)+" ")]):_vm._e()]}}],null,false,13692681)})],1)],1)],1)],1):_vm._e(),(_vm.documentoCpf)?_c('section',[_c('b-row',[_c('b-col',{attrs:{"xl":"4"}},[_c('b-form-group',{attrs:{"label":"Nome Completo*","label-for":"nome-completo-input","label-class":"font_size_label_empresa"}},[_c('validation-provider',{attrs:{"name":"NomeCompleto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('X'.repeat(255)),expression:"'X'.repeat(255)"}],attrs:{"state":errors.length > 0 || _vm.errorApi.nome || _vm.errorApi.error
                    ? false
                    : null,"id":"nome-completo-input","placeholder":"Nome completo","disabled":_vm.disabledAll ? _vm.disabledAll : _vm.pessoaFisicaFormDisabled},model:{value:(_vm.valueLocal.nome),callback:function ($$v) {_vm.$set(_vm.valueLocal, "nome", $$v)},expression:"valueLocal.nome"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.errorApi.nome)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.nome)+" ")]):_vm._e(),(_vm.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.error)+" ")]):_vm._e()]}}],null,false,2482313805)})],1)],1),_c('b-col',{attrs:{"xl":"4"}},[_c('b-form-group',{attrs:{"label":"Inscrição estadual*","label-for":"inscricao-estadual-input","label-class":"font_size_label_empresa"}},[_c('validation-provider',{attrs:{"name":"InscricaoEstadual","rules":"required|length:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###'),expression:"'###.###.###'"}],attrs:{"state":errors.length > 0 ||
                  _vm.errorApi.inscricao_estadual ||
                  _vm.errorApi.error
                    ? false
                    : null,"id":"inscricao-estadual-input","placeholder":"Inscrição estadual da empresa","disabled":_vm.disabledAll},model:{value:(_vm.valueLocal.inscricao_estadual),callback:function ($$v) {_vm.$set(_vm.valueLocal, "inscricao_estadual", $$v)},expression:"valueLocal.inscricao_estadual"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.errorApi.inscricao_estadual)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.inscricao_estadual)+" ")]):_vm._e(),(_vm.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.error)+" ")]):_vm._e()]}}],null,false,2649336109)})],1)],1),_c('b-col',{attrs:{"xl":"4"}},[_c('validation-provider',{attrs:{"name":"Sexo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{staticClass:"v-select-has-disabled",attrs:{"label":"Sexo*","label-for":"gender","state":errors.length > 0 ? false : null,"label-class":"font_size_label_empresa"}},[_c('v-select',{attrs:{"id":"status","options":['Masculino', 'Feminino'],"variant":"custom","item-text":"description","item-value":"value","placeholder":"Selecione o sexo","label":"description","disabled":_vm.disabledAll ? _vm.disabledAll : _vm.pessoaFisicaFormDisabled},model:{value:(_vm.valueLocal.genero),callback:function ($$v) {_vm.$set(_vm.valueLocal, "genero", $$v)},expression:"valueLocal.genero"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.error)+" ")]):_vm._e()],1)]}}],null,false,3510219737)})],1),_c('b-col',{attrs:{"xl":"4"}},[_c('validation-provider',{attrs:{"name":"DataNascimento","rules":"required|length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Data de Nascimento*","label-for":"data-nascimento-input","label-class":"font_size_label_empresa"}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"form-control",attrs:{"state":errors.length > 0 ||
                    _vm.errorApi.data_nascimento ||
                    (_vm.inputDataNascimentoError != 'inicio' &&
                      _vm.inputDataNascimentoError != '') ||
                    _vm.errorApi.error
                      ? false
                      : null,"id":"data-nascimento-input","placeholder":"00/00/0000","autocomplete":"off","disabled":_vm.disabledAll ? _vm.disabledAll : _vm.pessoaFisicaFormDisabled},on:{"input":_vm.setDataNascimentoInput},model:{value:(_vm.inputDataNascimento),callback:function ($$v) {_vm.inputDataNascimento=$$v},expression:"inputDataNascimento"}}),_c('b-input-group-append',[_c('b-form-datepicker',{staticClass:"form-control d-flex align-items-center",attrs:{"locale":"pt-BR","button-only":"","right":"","hide-header":true,"label-help":"Selecione a data de nascimento","button-variant":"custom-blue","clearable":"","disabled":_vm.disabledAll ? _vm.disabledAll : _vm.pessoaFisicaFormDisabled},on:{"input":_vm.setDataNascimentoPicker},model:{value:(_vm.valueLocal.data_nascimento),callback:function ($$v) {_vm.$set(_vm.valueLocal, "data_nascimento", $$v)},expression:"valueLocal.data_nascimento"}})],1)],1),(errors[0] && _vm.inputDataNascimentoError == 'inicio')?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.inputDataNascimentoError != 'inicio')?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.inputDataNascimentoError)+" ")]):_vm._e(),(_vm.errorApi.data_nascimento)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.data_nascimento)+" ")]):_vm._e(),(_vm.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.error)+" ")]):_vm._e()],1)]}}],null,false,1091121379)})],1),_c('b-col',{attrs:{"xl":"4"}},[_c('b-form-group',{attrs:{"label":"Nome da mãe","label-for":"nome-mae-input","label-class":"font_size_label_empresa"}},[_c('validation-provider',{attrs:{"name":"NomeMae"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.errorApi.nome_mae || _vm.errorApi.error
                    ? false
                    : null,"id":"nome-mae-input","placeholder":"Nome da mãe","disabled":_vm.disabledAll ? _vm.disabledAll : _vm.pessoaFisicaFormDisabled},model:{value:(_vm.valueLocal.nome_mae),callback:function ($$v) {_vm.$set(_vm.valueLocal, "nome_mae", $$v)},expression:"valueLocal.nome_mae"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.errorApi.nome_mae)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.nome_mae)+" ")]):_vm._e(),(_vm.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorApi.error)+" ")]):_vm._e()]}}],null,false,80221043)})],1)],1),_c('b-col',{attrs:{"xl":"4"}},[_c('NacionalidadeField',{key:_vm.valueLocal.id_nacionalidade,attrs:{"idNacionalidade":_vm.valueLocal.id_nacionalidade,"errorApiIdNacionalidade":_vm.errorApi.id_nacionalidade,"customClass":"empresa-tab-dados-principais v-select-has-disabled","isDisabled":_vm.disabledAll ? _vm.disabledAll : _vm.pessoaFisicaFormDisabled},on:{"updateNacionalidade":_vm.updateNacionalidade}})],1)],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }