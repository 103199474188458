<template>
  <div>
    <div class="campanha-departamento mt-2" v-if="possuiCampanhaDepartamento">
      <b-col
          sm="12"
          md="6"
          class="pl-0 pr-0"
      >
        <validation-provider
            #default="{ errors }"
            :name="'valor-diferenciado-departamento-' + indexValorDiferenciado"
            rules="required"
        >
          <b-form-group
              label="Departamento regional*"
              label-for="departamento"
          >
            <v-select
                id="departamento"
                v-model="valorDiferenciadoItem.departamentoSelecionado"
                :options="valorDiferenciadoItem.departamentoOptions"
                placeholder="Selecione o departamento regional"
                label="descricao"
                item-text="descricao"
                item-value="idDepartamento"
                :clearable="false"
                @input="() => resolverCampanhasDoValorDiferenciadoItem(valorDiferenciadoItem)"
                :disabled="valorDiferenciadoItem.jaExistente"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
            <small
                v-if="errors[0]"
                class="text-danger"
            >
              Este campo é de preenchimento obrigatório.
            </small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col
          sm="12"
          md="6"
          class="pr-0"
      >
        <validation-provider
            #default="{ errors }"
            :name="'valor-diferenciado-campanha-' + indexValorDiferenciado"
            rules="required"
        >
          <b-form-group
              label="Campanha*"
              label-for="campanha"
          >
            <v-select
                id="campanha"
                v-model="valorDiferenciadoItem.campanhaSelecionada"
                :options="valorDiferenciadoItem.campanhaOptions"
                placeholder="Selecione uma campanha"
                label="descricao"
                item-text="descricao"
                item-value="idCampanha"
                :clearable="false"
                :disabled="!valorDiferenciadoItem.departamentoSelecionado || valorDiferenciadoItem.jaExistente"
                @input="() => resolverVacinasValores(valorDiferenciadoItem)"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
            <small
                v-if="errors[0]"
                class="text-danger"
            >
              Este campo é de preenchimento obrigatório.
            </small>
          </b-form-group>

        </validation-provider>
      </b-col>
    </div>
    <b-col
        sm="12"
        md="12"
        :class="{ 'row-valor-diferenciado': possuiCampanhaDepartamento,  'row-valor-diferenciado pt-0 pb-0 sem-background': !possuiCampanhaDepartamento}"
    >
      <template v-if="valorDiferenciadoItem.campanhaSelecionada">
        <b-row
            v-for="(vacinaValores, index) in valorDiferenciadoItem.vacinasValores"
            :key="index"
            class="mt-1"
        >
          <b-col
              sm="12"
              md="3"
              class="col-vacina"
          >
            <label class="label-valor-diferenciado vacina-nome">
              {{ vacinaValores.vacina }}
            </label>
          </b-col>

          <b-col
              v-for="(valorCategoria, index) in vacinaValores.valores"
              :key="index"
              sm="12"
              md="3"
          >
            <ValorDiferenciadoInput
                :id="valorCategoria.id"
                :value="valorCategoria.value"
                :label="valorCategoria.label"
                :nome-provider="'valor-diferenciado-' + indexValorDiferenciado + '-input-' + valorCategoria.id"
                @change="(valor) => editarValorDeValorCategoria(valorCategoria, valor, indexValorDiferenciado)"
            />
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <label class="selecione-campanha">Selecione a campanha</label>
      </template>
      <b-col
          sm="12"
          md="12"
          class="remover mt-2"
      >
        <div class="text-danger cursor-pointer remover-valor-diferenciado"
             @click.prevent="() => removerValorDiferenciado(indexValorDiferenciado, valorDiferenciadoItem)"
        >
          <feather-icon icon="XIcon"/><span>Remover Valor Diferenciado</span>
        </div>
      </b-col>
    </b-col>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {BCol, BFormGroup, BRow} from "bootstrap-vue";
import {ValidationProvider} from "vee-validate";
import ValorDiferenciadoInput
  from "@/views/custom-pages/empresa/tabs-empresa/partes/valor-diferenciado/Valor-diferenciado-input.vue";

export default {
  components: {BRow, ValorDiferenciadoInput, BFormGroup, ValidationProvider, BCol, vSelect},
  props: {
    valorDiferenciadoItem: {
      required: true
    },
    indexValorDiferenciado: {
      required: true
    },
    possuiCampanhaDepartamento: {
      required: false,
      default: true
    }
  },
  methods: {
    resolverVacinasValores(valorDiferenciadoItem) {
      this.$emit('resolverVacinasValores', valorDiferenciadoItem)
    },
    resolverCampanhasDoValorDiferenciadoItem(valorDiferenciadoItem) {
      this.$emit('resolverCampanhasDoValorDiferenciadoItem', valorDiferenciadoItem)
    },
    editarValorDeValorCategoria(valorCategoria, valor, indexValorDiferenciado) {
      this.$emit('editarValorDeValorCategoria', valorCategoria, valor, indexValorDiferenciado)
    },
    removerValorDiferenciado(indexValorDiferenciado, valorDiferenciadoItem) {
      this.$emit('removerValorDiferenciado', indexValorDiferenciado, valorDiferenciadoItem)
    }
  }
}
</script>

<style scoped lang="scss">
 .sem-background {
   background: none !important;
 }
</style>