<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <validation-provider name="id_nacionalidade" #default="{ errors }">
      <b-form-group
        label="Nacionalidade"
        v-show="!showLoading"
        :state="errorApiIdNacionalidade.length > 0 ? false : null"
        class="m-0"
        :class="customClass"
      >
        <v-select
          id="select-nacionalidade"
          v-model="nacionalidadeSelecionada"
          name="nacionalidade"
          variant="custom"
          :options="nacionalidades"
          placeholder="Selecione uma nacionalidade"
          label="descricao"
          track-by="id_nacionalidade"
          @input="updateNacionalidade"
          :disabled="isDisabled"
        >
          <span slot="no-options">Nenhum registro encontrado.</span>
        </v-select>
      </b-form-group>

      <small v-if="errorApiIdNacionalidade" class="text-danger">
        {{ errorApiIdNacionalidade }}
      </small>
    </validation-provider>
  </b-overlay>
</template>

<script>
import vSelect from "vue-select";
import { BFormGroup, BOverlay } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    vSelect,
    BFormGroup,
    BOverlay,
    ValidationProvider,
  },
  props: {
    idNacionalidade: {
      type: Number,
      required: false,
      default: 0,
    },
    errorApiIdNacionalidade: {
      type: String,
      required: false,
      default: "",
    },
    customClass: {
      type: String,
      required: false,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showLoading: true,
      nacionalidadeSelecionada: null,
      nacionalidades: [],
    };
  },
  beforeMount() {
    this.$http.get(this.$api.nacionalidade()).then(({ data }) => {
      this.nacionalidades = data.data != undefined ? data.data : data;

      let idNacionalidade = this.idNacionalidade;
      if (this.isDisabled && this.customClass != "register-company-user") {
        idNacionalidade = idNacionalidade > 0 ? idNacionalidade : null;
        this.nacionalidades.push({
          id_nacionalidade: idNacionalidade,
          descricao: "Não informado",
        });
      }

      this.nacionalidadeSelecionada = this.nacionalidades.find(
        (nac) => nac.id_nacionalidade == this.idNacionalidade
      );
    });
  },

  created() {
    this.showLoading = !this.showLoading;
  },

  methods: {
    updateNacionalidade() {
      this.$emit(
        "updateNacionalidade",
        this.nacionalidadeSelecionada == null
          ? this.nacionalidadeSelecionada
          : this.nacionalidadeSelecionada.id_nacionalidade
      );
    },
  },

  watch: {
    nacionalidadeSelecionada() {
      this.updateNacionalidade();
    },
  },
};
</script>

<style lang="scss">
.register-colaborador,
.edit-colaborador,
.empresa-tab-dados-principais,
.register-usuario,
.register-company-user,
.link-first-company,
.edit-company-user-colaborador {
  label {
    font-size: 14px;
    line-height: inherit;
  }
}
</style>