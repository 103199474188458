<template>
  <main>
    <b-row>
      <b-col xl="6" cols="12">
        <b-form-group
          label="CNPJ ou CPF*"
          label-for="cnpj-cpf-input"
          label-class="font_size_label_empresa"
        >
          <validation-provider
            #default="{ errors }"
            name="Documento"
            :rules="documentoCnpj ? 'required|length:18' : 'required|length:14'"
          >
            <b-form-input
              :state="
                errors.length > 0 ||
                errorApi.documento ||
                (documentoError != 'inicio' && documentoError != '') ||
                errorApi.error
                  ? false
                  : null
              "
              class="form-control"
              id="cnpj-cpf-input"
              placeholder="00.000.000/0000-00"
              v-model="valueLocal.documento"
              @input="validaDocumento"
              :disabled="edicao ? edicao : documentoDisabled"
              v-mask="
                valueLocal.documento.length <= 14 && !documentoCnpj
                  ? '###.###.###-##'
                  : '##.###.###/####-##'
              "
            />
            <small
              v-if="errors[0] && documentoError == 'inicio'"
              class="text-danger"
            >
              Este campo é de preenchimento obrigatório.
            </small>
            <small v-if="documentoError != 'inicio'" class="text-danger">
              {{ documentoError }}
            </small>
            <small v-if="errorApi.documento" class="text-danger">
              {{ errorApi.documento }}
            </small>
            <small v-if="errorApi.error" class="text-danger">
              {{ errorApi.error }}
            </small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-overlay
      :show="!loadingShow"
      rounded="lg"
      opacity="0.6"
      class="w-100 mt-1"
    >
      <section v-if="documentoCnpj">
        <b-row>
          <b-col xl="6">
            <b-form-group
              label="Nome Fantasia*"
              label-for="nome-fantasia-input"
              label-class="font_size_label_empresa"
            >
              <validation-provider
                #default="{ errors }"
                name="NomeFantasia"
                rules="required"
              >
                <b-form-input
                  :state="
                    errors.length > 0 ||
                    errorApi.nome_fantasia ||
                    errorApi.error
                      ? false
                      : null
                  "
                  id="nome-fantasia-input"
                  v-model="valueLocal.nome_fantasia"
                  placeholder="Nome fantasia da empresa"
                  :disabled="disabledAll"
                  v-mask="'X'.repeat(255)"
                />
                <small v-if="errors[0]" class="text-danger">
                  Este campo é de preenchimento obrigatório.
                </small>
                <small v-if="errorApi.nome_fantasia" class="text-danger">
                  {{ errorApi.nome_fantasia }}
                </small>
                <small v-if="errorApi.error" class="text-danger">
                  {{ errorApi.error }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col xl="6">
            <b-form-group
              label="Razão Social*"
              label-for="razao-social-input"
              label-class="font_size_label_empresa"
            >
              <validation-provider
                #default="{ errors }"
                name="RazaoSocial"
                rules="required"
              >
                <b-form-input
                  :state="
                    errors.length > 0 || errorApi.razao_social || errorApi.error
                      ? false
                      : null
                  "
                  id="razao-social-input"
                  v-model="valueLocal.razao_social"
                  placeholder="Razão social da empresa"
                  :disabled="disabledAll"
                  v-mask="'X'.repeat(255)"
                />
                <small v-if="errors[0]" class="text-danger">
                  Este campo é de preenchimento obrigatório.
                </small>
                <small v-if="errorApi.razao_social" class="text-danger">
                  {{ errorApi.razao_social }}
                </small>
                <small v-if="errorApi.error" class="text-danger">
                  {{ errorApi.error }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </section>
      <section v-if="documentoCpf">
        <b-row>
          <b-col xl="4">
            <b-form-group
              label="Nome Completo*"
              label-for="nome-completo-input"
              label-class="font_size_label_empresa"
            >
              <validation-provider
                #default="{ errors }"
                name="NomeCompleto"
                rules="required"
              >
                <b-form-input
                  :state="
                    errors.length > 0 || errorApi.nome || errorApi.error
                      ? false
                      : null
                  "
                  id="nome-completo-input"
                  v-model="valueLocal.nome"
                  placeholder="Nome completo"
                  :disabled="
                    disabledAll ? disabledAll : pessoaFisicaFormDisabled
                  "
                  v-mask="'X'.repeat(255)"
                />
                <small v-if="errors[0]" class="text-danger">
                  Este campo é de preenchimento obrigatório.
                </small>
                <small v-if="errorApi.nome" class="text-danger">
                  {{ errorApi.nome }}
                </small>
                <small v-if="errorApi.error" class="text-danger">
                  {{ errorApi.error }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col xl="4">
            <b-form-group
              label="Inscrição estadual*"
              label-for="inscricao-estadual-input"
              label-class="font_size_label_empresa"
            >
              <validation-provider
                #default="{ errors }"
                name="InscricaoEstadual"
                rules="required|length:11"
              >
                <b-form-input
                  :state="
                    errors.length > 0 ||
                    errorApi.inscricao_estadual ||
                    errorApi.error
                      ? false
                      : null
                  "
                  id="inscricao-estadual-input"
                  v-model="valueLocal.inscricao_estadual"
                  placeholder="Inscrição estadual da empresa"
                  v-mask="'###.###.###'"
                  :disabled="
                    disabledAll
                  "
                />
                <small v-if="errors[0]" class="text-danger">
                  Este campo é de preenchimento obrigatório.
                </small>
                <small v-if="errorApi.inscricao_estadual" class="text-danger">
                  {{ errorApi.inscricao_estadual }}
                </small>
                <small v-if="errorApi.error" class="text-danger">
                  {{ errorApi.error }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col xl="4">
            <validation-provider
              #default="{ errors }"
              name="Sexo"
              rules="required"
            >
              <b-form-group
                label="Sexo*"
                label-for="gender"
                :state="errors.length > 0 ? false : null"
                label-class="font_size_label_empresa"
                class="v-select-has-disabled"
              >
                <v-select
                  id="status"
                  v-model="valueLocal.genero"
                  :options="['Masculino', 'Feminino']"
                  variant="custom"
                  item-text="description"
                  item-value="value"
                  placeholder="Selecione o sexo"
                  label="description"
                  :disabled="
                    disabledAll ? disabledAll : pessoaFisicaFormDisabled
                  "
                />

                <small v-if="errors[0]" class="text-danger">
                  Este campo é de preenchimento obrigatório.
                </small>
                <small v-if="errorApi.error" class="text-danger">
                  {{ errorApi.error }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col xl="4">
            <validation-provider
              #default="{ errors }"
              name="DataNascimento"
              rules="required|length:10"
            >
              <b-form-group
                label="Data de Nascimento*"
                label-for="data-nascimento-input"
                label-class="font_size_label_empresa"
              >
                <b-input-group>
                  <b-form-input
                    :state="
                      errors.length > 0 ||
                      errorApi.data_nascimento ||
                      (inputDataNascimentoError != 'inicio' &&
                        inputDataNascimentoError != '') ||
                      errorApi.error
                        ? false
                        : null
                    "
                    class="form-control"
                    id="data-nascimento-input"
                    v-model="inputDataNascimento"
                    v-mask="'##/##/####'"
                    placeholder="00/00/0000"
                    autocomplete="off"
                    @input="setDataNascimentoInput"
                    :disabled="
                      disabledAll ? disabledAll : pessoaFisicaFormDisabled
                    "
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      class="form-control d-flex align-items-center"
                      locale="pt-BR"
                      button-only
                      right
                      :hide-header="true"
                      v-model="valueLocal.data_nascimento"
                      label-help="Selecione a data de nascimento"
                      button-variant="custom-blue"
                      clearable
                      @input="setDataNascimentoPicker"
                      :disabled="
                        disabledAll ? disabledAll : pessoaFisicaFormDisabled
                      "
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="errors[0] && inputDataNascimentoError == 'inicio'"
                  class="text-danger"
                >
                  Este campo é de preenchimento obrigatório.
                </small>
                <small
                  v-if="inputDataNascimentoError != 'inicio'"
                  class="text-danger"
                >
                  {{ inputDataNascimentoError }}
                </small>
                <small v-if="errorApi.data_nascimento" class="text-danger">
                  {{ errorApi.data_nascimento }}
                </small>
                <small v-if="errorApi.error" class="text-danger">
                  {{ errorApi.error }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col xl="4">
            <b-form-group
              label="Nome da mãe"
              label-for="nome-mae-input"
              label-class="font_size_label_empresa"
            >
              <validation-provider #default="{ errors }" name="NomeMae">
                <b-form-input
                  :state="
                    errors.length > 0 || errorApi.nome_mae || errorApi.error
                      ? false
                      : null
                  "
                  id="nome-mae-input"
                  v-model="valueLocal.nome_mae"
                  placeholder="Nome da mãe"
                  :disabled="
                    disabledAll ? disabledAll : pessoaFisicaFormDisabled
                  "
                />
                <small v-if="errors[0]" class="text-danger">
                  Este campo é de preenchimento obrigatório.
                </small>
                <small v-if="errorApi.nome_mae" class="text-danger">
                  {{ errorApi.nome_mae }}
                </small>
                <small v-if="errorApi.error" class="text-danger">
                  {{ errorApi.error }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col xl="4">
            <NacionalidadeField
              :key="valueLocal.id_nacionalidade"
              :idNacionalidade="valueLocal.id_nacionalidade"
              @updateNacionalidade="updateNacionalidade"
              :errorApiIdNacionalidade="errorApi.id_nacionalidade"
              customClass="empresa-tab-dados-principais v-select-has-disabled"
              :isDisabled="disabledAll ? disabledAll : pessoaFisicaFormDisabled"
            />
          </b-col>
        </b-row>
      </section>
    </b-overlay>
  </main>
</template>
<script>
import {
  BCard,
  BFormInput,
  BCol,
  BRow,
  BOverlay,
  BFormGroup,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import { required } from "@validations";
import moment from "moment";
import vSelect from "vue-select";
import NacionalidadeField from "@/views/components/custom/field-select-nacionalidade/NacionalidadeField.vue";

export default {
  components: {
    BCard,
    BFormInput,
    BCol,
    BRow,
    BOverlay,
    ValidationProvider,
    BFormGroup,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    moment,
    vSelect,
    NacionalidadeField,
  },

  props: {
    errorApi: {
      type: Object,
      required: true,
    },
    disabledAll: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    edicao: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loadingShow: true,
      documentoError: "inicio",
      inputDataNascimentoError: "inicio",
      documentoCnpj: true,
      documentoCpf: false,
      inputDataNascimento: null,
      timeID: null,
      timeIdData: null,
      erros: null,
      pessoaFisicaFormDisabled: false,
      required,
      documentoDisabled: false,
      valueLocal:{}
    };
  },

  beforeMount() {
    this.valueLocal = this.value
  },
  mounted() {
      if (this.edicao && this.value.documento) {
          this.value.documento.replace(/[^0-9]/g,'').length >= 14 ? this.mostraFormCnpj() : this.mostraFormCpf();
          this.setDataNascimentoPicker();
      }        
  },
  methods: {
    documentoComErroObrigatorio(documento) {
      if (documento.length == 0) {
        this.documentoError = "Este campo é de preenchimento obrigatório.";
        this.pessoaFisicaFormDisabled = false;
        return;
      }
    },
    documentoComErroCPFQuantidadeCaracteres(documento) {
      if (documento.length > 0 && documento.length < 14) {
        this.mostraFormCpf();
        this.cadastroEstadoEmpresa(false);
        this.documentoError = "CPF deve ter 14 caracteres com 11 números";
        this.pessoaFisicaFormDisabled = false;
        return;
      }
    },
    documentoCPFCorreto(documento) {
      if (documento.length == 14) {
        this.$emit("update:travaSaving", true);
        this.loadingShow = false;
        this.mostraFormCpf();
        this.documentoDisabled = true;
        this.cadastroEstadoEmpresa(false);
        this.verificaCpfEPreencheDados();
        return;
      }
    },
    documentoComErroCNPJQuantidadeCaracteres(documento) {
      if (
        (documento.length > 14 && documento.length < 18) ||
        documento.length > 18
      ) {
        this.mostraFormCnpj();
        this.cadastroEstadoEmpresa(true);
        this.$emit("update:cnpjFaturamento", documento);
        this.documentoError = "CNPJ deve ter 18 caracteres com 14 números";
        return;
      }
    },
    documentoCNPJCorreto(documento) {
      if (documento.length == 18) {
        this.$emit("update:travaSaving", true);
        this.mostraFormCnpj();
        this.documentoDisabled = true;
        this.cadastroEstadoEmpresa(true);
        this.verificaCnpjEPreencheDados();
        this.$emit("update:cnpjFaturamento", documento);
        return;
      }
    },
    validaDocumento() {
      if (!this.$props.edicao) {
        this.$emit("update:apagaErro400Api", "");
        this.documentoError = "";
        clearTimeout(this.timeID);
        this.timeID = setTimeout(() => {
          this.documentoComErroObrigatorio(this.valueLocal.documento);
          this.documentoComErroCPFQuantidadeCaracteres(this.valueLocal.documento);
          this.documentoCPFCorreto(this.valueLocal.documento);
          this.documentoComErroCNPJQuantidadeCaracteres(this.valueLocal.documento);
          this.documentoCNPJCorreto(this.valueLocal.documento);
        }, 600);
      } else {
        this.$emit("update:cnpjFaturamento", this.valueLocal.documento);
      }
    },

    verificaCnpjEPreencheDados() {
      this.retornaIconeEAbaErro(false, 0);
      const cnpj = this.$helpers.somenteNumeros(this.valueLocal.documento);
      const payload = {
        cnpj: cnpj,
        api_externa: true,
      };
      this.$http
        .post(this.$api.getCompanyByCnpjWithExternalApi(), payload, {
          timeout: 8000,
        })
        .then(({ data }) => {
          if (
            data &&
            Object.keys(data).length > 0 &&
            data.constructor === Object
          ) {
            this.$emit("dadosApiEmpresaCnpj", data);
          }

          if (data.length == 0) {
            this.$emit("dadosApiEmpresaCnpjVazio");
          }

          this.disableDocumentAndBusinessFunctions();
        })
        .catch((error) => {
          if (error && error.response && error.response.status == 400) {
            this.retornaIconeEAbaErro(true, 0);
            this.documentoError = error.response.data.errors;
          } else {
            this.documentoError =
              "A busca de dados por cnpj não foi bem-sucedida, mas você pode continuar o cadastro.";
          }
          this.disableDocumentAndBusinessFunctions();
        });
    },

    verificaCpfEPreencheDados() {
      this.retornaIconeEAbaErro(false, 0);
      const cpf = this.$helpers.somenteNumeros(this.valueLocal.documento);

      this.$http
        .get(this.$api.getPessoaFisicaPorCpf(cpf))
        .then(({ data }) => {
          if (data == "") {
            this.pessoaFisicaFormDisabled = false;
          } else {
            this.alterarCamposCPFApi(
              data.nome,
              data.data_nascimento,
              data.genero,
              data.nome_mae,
              data.id_nacionalidade
            );
            this.setDataNascimentoPicker();
            this.pessoaFisicaFormDisabled = true;
          }
          this.disableDocumentAndBusinessFunctions();
        })
        .catch(({ response: { status, data } }) => {
          if (status != 422) {
            this.pessoaFisicaFormDisabled = true;
            this.alterarCamposCPFApi();
            this.$helpers.modalErroServidorPadrao();
          } else {
            this.pessoaFisicaFormDisabled = false;
            this.retornaIconeEAbaErro(true, 0);
            this.documentoError = data.errors.cpf[0];
          }
          this.disableDocumentAndBusinessFunctions();
        });
    },

    alterarCamposCPFApi(nome = "", dataNascimento = "", genero = "", nomeMae = "", idNacionalidade = null) {
      this.$emit("update:nomeCpfForm", nome);
      this.$emit("update:dataNascimentoForm", dataNascimento);
      this.$emit("update:generoForm", genero);
      this.inputDataNascimento =
        dataNascimento == "" || dataNascimento == null
          ? ""
          : moment(dataNascimento).format("DD/MM/YYYY");
      
      this.valueLocal.genero = genero
      this.valueLocal.nome_mae = nomeMae
      this.valueLocal.id_nacionalidade = idNacionalidade
      this.inputDataNascimentoError = "";
    },

    mostraFormCpf() {
      this.documentoCnpj = false;
      this.documentoCpf = true;
    },

    mostraFormCnpj() {
      this.documentoCnpj = true;
      this.documentoCpf = false;
    },

    cadastroEstadoEmpresa(cnpj) {
      this.$emit("update:mudancaEstado", cnpj);
    },

    setDataNascimentoPicker() {
      this.inputDataNascimentoError = "";
      this.inputDataNascimento = moment(this.valueLocal.data_nascimento).format(
        "DD/MM/YYYY"
      );
    },

    retornaIconeEAbaErro(boolean, numeroAba) {
      this.$emit("update:ativarIconeAba", boolean);
      this.$emit("update:alterarAba", numeroAba);
    },

    setDataNascimentoInput() {
      this.inputDataNascimentoError = "";
      clearTimeout(this.timeIdData);
      this.timeIdData = setTimeout(() => {
        if (this.inputDataNascimento.length == 0) {
          this.inputDataNascimentoError =
            "Este campo é de preenchimento obrigatório.";
        }
        if (
          (this.inputDataNascimento.length > 0 &&
            this.inputDataNascimento.length < 10) ||
          this.inputDataNascimento.length > 10
        ) {
          this.inputDataNascimentoError =
            "A data de nascimento deve ser uma data válida";
        }
        if (this.inputDataNascimento.length == 10) {
          this.$emit(
            "update:dataNascimentoForm",
            moment(this.inputDataNascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
          );
        }
      }, 1000);
    },

    disableDocumentAndBusinessFunctions() {
      this.loadingShow = true;
      this.$emit("update:travaSaving", false);
      this.documentoDisabled = false;
    },

    updateNacionalidade(id_nacionalidade) {
      this.valueLocal.id_nacionalidade = id_nacionalidade;
    },
  },

  watch: {
    value() {
      this.$emit("input", this.value);
    },
    valueLocal() {
      this.$emit("input", this.valueLocal);
    },
  },
};
</script>
<style>
.fonte_titulo_modal {
  font-size: 21px;
  font-weight: 500;
  font-family: "Montserrat";
}
.fonte_corpo_modal {
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat";
}
</style>
