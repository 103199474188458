<template>
    <b-modal id="show-aviso" no-close-on-esc no-close-on-backdrop hide-header hide-footer centered v-model="showModal">
      <div class="d-flex flex-column align-items-center justify-center-center my-2 text-center">
        <feather-icon id="modal-confirm" icon="AlertTriangleIcon" size="80" class="icon-pendencia"/>
        <h2 class="text-center">Unidade Operacional vinculada ao endereço</h2>
  
        <p class="modal-text">
            Um dos endereços informados possui atendimento por mais de uma unidade operacional. Caso a unidade sugerida não atenda este endereço,
            você pode alterá-la manualmente nesta tela.
        </p>
        
        <div class="d-flex mt-1">
          <b-button variant="primary-button" class="mr-2" @click="confirmAction">
            Entendido
          </b-button>
        </div>
      </div>
    </b-modal>
  </template>
    
<script>
  import { BModal, BButton, BLink } from 'bootstrap-vue';
  
    export default {
    components: {
      BModal, BButton, BLink
    },
    props: {
      showModal: {
        type: Boolean,
        default: false
      },
    },
    methods: {
      confirmAction(modalEvent) {
        this.$emit('confirmed');
        modalEvent.preventDefault();
      }
    }
  
  }
  </script>
    
  <style scoped>
  .bloco {
    height: 100px;
  }
  
  .avisos {
    background: linear-gradient(60deg, #115EAD 0%, #2772c0 100%);
    width: 100%;
    box-sizing: border-box;
  }
  
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }
  
  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
  
  .panel-enter-active,
  .panel-leave-active {
    transition: all 0.5s ease;
  }
  
  .panel-enter-from,
  .panel-leave-to {
    opacity: 0;
    height: 0;
    transform: translateX(30px);
  }
  
  .icon-pendencia {
    color: #FF9110;
    margin-bottom: 18px;
  }
  
  .modal-text {
    font-size: 14px;
    line-height: 21px;
    max-width: 383px;
  }
  </style>
    