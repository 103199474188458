var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{ref:"provider",attrs:{"name":_vm.nomeProvider,"rules":'inputRule'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[(_vm.label)?_c('label',{staticClass:"label-valor-diferenciado",attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('b-input-group',[_c('b-input-group-prepend',[_c('div',{class:{
                             'bg-custom-blue d-flex align-items-center custom-append-input': true,
                             'disabled-append': false
                          }},[_c('b-img',{attrs:{"src":_vm.Ricon,"alt":"Icone que representa o real"}})],1)]),_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.moneyMask),expression:"moneyMask"}],class:{
                          'border-color-input': true,
                          'border-color-input-disabled': true
                        },attrs:{"value":_vm.value,"id":_vm.id,"placeholder":"0,00","disabled":false},on:{"keydown":function($event){$event.key === '-' && $event.preventDefault()},"change":_vm.handleInput}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }