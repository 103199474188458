<template>
  <section>
    <b-row>
        <b-col xl="12" cols="12">
            <div class="radio-inline-spacing">
                <label class="font_size_label_empresa font-weight-light">Usar o mesmo CNPJ para faturamento?</label>
                <b-form-radio
                    :state="errorApi.error ? false:null"
                    v-model="value.radioMesmoCnpjFaturamento"
                    name="radio-mesmo-cnpj"
                    :value="true"
                >
                    Sim
                </b-form-radio>
                <b-form-radio
                    :state="errorApi.error ? false:null"
                    v-model="value.radioMesmoCnpjFaturamento"
                    name="radio-mesmo-cnpj"
                    :value="false"
                >
                    Não
                </b-form-radio>
            </div>
            <small v-if="errorApi.error" class="text-danger">
                {{ errorApi.error }}
            </small>
        </b-col>
        <b-col
            v-if="value.radioMesmoCnpjFaturamento == true"
            xl="5" 
            cols="12"  
            class="mt-2"
        >
            <b-form-group 
            label="CNPJ de Faturamento"
            label-for="mesmo-cnpj-faturamento-input"
            label-class="font_size_label_empresa">
                <b-form-input
                    id="mesmo-cnpj-faturamento-input" 
                    placeholder="00.000.000/0000-00"
                    v-model="mesmoCnpjFaturamento"
                    v-mask="'##.###.###/####-##'"
                    :disabled="true"
                />
                <small v-if="errorApi.cnpj_faturamento" class="text-danger">
                    {{ errorApi.cnpj_faturamento }}
                </small>
                <small v-if="errorApi.error" class="text-danger">
                    {{ errorApi.error }}
                </small>
            </b-form-group>
        </b-col>
        <b-col
            v-else
            xl="5" 
            cols="12"  
            class="mt-2"
        >
            <b-form-group 
                label="CNPJ de Faturamento"
                label-for="cnpj-faturamento-input"
                label-class="font_size_label_empresa"
            >
                <validation-provider
                    #default="{ errors }"
                    name="CNPJFaturamento"
                    :rules="'required|length:14'"
                >
                    <vue-cleave
                        id="cnpj-faturamento-input" 
                        v-model="value.cnpjFaturamento"
                        :raw="true"
                        :options="mascaraCnpj"
                        class="form-control"
                        placeholder="00.000.000/0000-00"
                        name="CnpjFaturamento"
                    />
                    <small v-if="errors[0]" class="text-danger">
                        Este campo deve ter 14 números
                    </small>
                    <small v-if="errorApi.cnpj_faturamento" class="text-danger">
                        {{ errorApi.cnpj_faturamento }}
                    </small>
                    <small v-if="errorApi.error" class="text-danger">
                        {{ errorApi.error }}
                    </small>
                </validation-provider>
            </b-form-group>
        </b-col>

      <b-col v-if="this.$can(actions.VISUALIZAR, subjects.CAMPANHA_VACINA_EMPRESA)"
          xl="12"
          cols="12"
          class="mt-2"
      >
        <Valor-diferenciado
            v-model="value.valorDiferenciado"
            :error-api="errorApi"
            :empresa-possui-valor-diferenciado="value.empresaPossuiValorDiferenciado"
            :pode-cadastrar="!dadosComplementares.base_nacional && this.$can(actions.INSERIR, subjects.CAMPANHA_VACINA_EMPRESA)"
            @novoValorDiferenciado="novoValorDiferenciado"
            @removerValorDiferenciado="removerValorDiferenciado"
            @atualizarValorDiferenciadoItem="atualizarValorDiferenciadoItem"
        />
      </b-col>

    </b-row>

  </section>
</template>
<script>

import { BCard, BFormInput, BCol, BRow, BOverlay, BFormGroup, BFormRadio } from 'bootstrap-vue'
import { ValidationProvider } from "vee-validate"
import VueCleave from 'vue-cleave-component'
import ValorDiferenciado from "@/views/custom-pages/empresa/tabs-empresa/partes/valor-diferenciado/Valor-diferenciado.vue";
import {actions, subjects} from "@/libs/acl/rules";

export default {
  computed: {
    subjects() {
      return subjects
    }, actions() {
      return actions
    }
  },
    components: {
      ValorDiferenciado,
        BCard,
        BFormInput,
        BCol,
        BRow,
        BOverlay,
        BFormGroup,
        BFormRadio,
        ValidationProvider,
        VueCleave
    },

    props: {
        errorApi: {
            type: Object,
            required: true
        },
        mesmoCnpjFaturamento: {
            type: String,
            required: true
        },
        value: {
            type: Object,
            required: true
        },
        dadosComplementares: {
          type: Object,
          required: true
        }
    },

    data() {
        return {
            mascaraCnpj: {
                delimiters: ['.', '.', '/', '-'],
                blocks: [2, 3, 3, 4, 2],
                numericOnly: true
            },
        }
    },

  methods: {
      novoValorDiferenciado(valorDiferenciado) {
        this.value.valorDiferenciado.unshift(valorDiferenciado);
      },
      removerValorDiferenciado(index) {
        this.value.valorDiferenciado.splice(index, 1);
      },
      atualizarValorDiferenciadoItem({indexValorDiferenciado, valorDiferenciadoInput}) {
        const valorDiferenciadoItem = this.value.valorDiferenciado[indexValorDiferenciado];

        if (valorDiferenciadoItem) {
          this.atualizarValoresValorDiferenciado(
              valorDiferenciadoItem,
              valorDiferenciadoInput.vacinasValores
          );
        }
      },

      atualizarValoresValorDiferenciado(valorDiferenciadoItem, vacinasValoresNovos) {
        vacinasValoresNovos.forEach(vacinaValorNovo => {
          const vacinaValorExistente = valorDiferenciadoItem.vacinasValores.find(
              valorExistente => vacinaValorNovo.idCampanhaVacina === valorExistente.id_campanha_vacina
          );

          if (vacinaValorExistente) {
            vacinaValorNovo.valores.forEach(valorNovo => {
              const vacinaValoresCategoriaExistente = vacinaValorExistente.valores.find(
                  vacinaValorCategoriaExistente => valorNovo.id_categoria === vacinaValorCategoriaExistente.id_categoria
              );

              vacinaValoresCategoriaExistente.value = valorNovo.value;
              vacinaValoresCategoriaExistente.id_campanha_vacina = valorNovo.id_campanha_vacina;
            });
          }
        });
      },
    },
    watch: {
        value() {
            this.$emit('input', this.value);
        }
    }
}
</script>
<style lang="scss" scoped>
.radio-inline-spacing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > * {
    margin-right: 1.5rem;
    margin-bottom: 0.2rem;
  }
}
</style>