
<template>
  <validation-provider
      ref="provider"
      #default="{ errors }"
      :name="nomeProvider"
      :rules="'inputRule'"
  >
    <b-form-group>
      <label v-if="label" class="label-valor-diferenciado" :for="id">{{ label }}</label>
      <b-input-group>
        <b-input-group-prepend>
          <div
              :class="{
                               'bg-custom-blue d-flex align-items-center custom-append-input': true,
                               'disabled-append': false
                            }"
          >
            <b-img :src="Ricon" alt="Icone que representa o real"></b-img>
          </div>
        </b-input-group-prepend>
        <b-form-input
            :value="value"
            @keydown="$event.key === '-' && $event.preventDefault()"
            :class="{
                            'border-color-input': true,
                            'border-color-input-disabled': true
                          }"
            v-money="moneyMask"
            :id="id"
            placeholder="0,00"
            :disabled="false"
            @change="handleInput"
        />
      </b-input-group>
    </b-form-group>
    <small v-if="errors[0]" class="text-danger">
      Este campo é de preenchimento obrigatório.
    </small>
  </validation-provider>

</template>

<script>
import {BFormGroup, BFormInput, BImg, BInputGroup, BInputGroupPrepend} from "bootstrap-vue";
import Ricon from '@/assets/images/icons/RIcon.svg';
import {ValidationProvider} from "vee-validate";
import { extend } from 'vee-validate';

export default {
  components: {ValidationProvider, BInputGroup, BImg, BFormGroup, BFormInput, BInputGroupPrepend},
  props: {
    label: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: true
    },
    value: {
      required: false,
      default: 0
    },
    nomeProvider: {
      type: String,
      required: false,
    }
  },
  created() {
    extend('inputRule', {
      validate: (value) => {
        const unmaskedValue = value.replace(/\./g, '').replace(',', '.');
        const numericValue = parseFloat(unmaskedValue);

        return numericValue > 0;
      }
    });
  },
  data() {
    return {
      moneyMask: {
        decimal: ',',
        thousands: '.',
        precision: 2,
      },
      Ricon,
      timeout: null,
    }
  },
  methods: {
    handleInput(value) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.emitChangeValue(value);
      }, 500);

    },
    emitChangeValue(value) {
      this.$emit('change', value);
    }
  }
}
</script>
